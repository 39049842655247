
import * as vercelRuntime$iQAI9aWop6 from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1280,
    "xxl": 1366,
    "2xl": 3072,
    "smx": 428,
    "2lg": 2048
  },
  "presets": {},
  "provider": "vercel",
  "domains": [
    "main--alaskapro.netlify.app",
    "cp.trueislam.co.uk",
    "trueislam.local",
    "trueislam.local",
    "localhost"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$iQAI9aWop6, defaults: {} }
}
        